import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        ptName: "能耗双控管理云平台",
        userInfo: localStorage["guser"] ?
            JSON.parse(localStorage["guser"]) : {} || {},
        year: [],
        showType: 0,
        month: [{
                val: "1",
            },
            {
                val: "2",
            },
            {
                val: "3",
            },
            {
                val: "4",
            },
            {
                val: "5",
            },
            {
                val: "6",
            },
            {
                val: "7",
            },
            {
                val: "8",
            },
            {
                val: "9",
            },
            {
                val: "10",
            },
            {
                val: "11",
            },
            {
                val: "12",
            },
        ],
        userType: localStorage["guser"] ?
            JSON.parse(localStorage["guser"]).type : "" || "",
    },
    mutations: {
        setUserInfo: function(state, payload) {
            state.userInfo = payload;
        },
        setYear: function(state, payload) {
            state.year = payload.year;
        },
        setShow: function(state, payload) {
            state.showType = payload.showType;
        },
    },
    actions: {
        // 第二步 store感知你触发了一个叫做change的action，执行action
        change(store, str) {
            // 第三步，提交一个 commit 触发一个mutation
            setTimeout(()=>{
                // console.log('actions - change')
                this.commit('setUserInfo', str)
            },1000)
            // console.log(store)
            // console.log(str)
        }
    },
    modules: {},
});