import Vue from "vue";
import { mapState } from "vuex";
Vue.mixin({
    data() {
        return {
            iconyellow: require('../assets/image/bj_ic_cheng.png'),
            iconyered: require('../assets/image/bj_ic_hong.png'),
            icongreen: require('../assets/image/bj_ic_lv.png'),
        };
    },
    computed: {
        ...mapState(["year", "userType", "userInfo", "ptName", "month"]),
    },

    created() {},
    methods: {
        setStoreIndex: function(index) {
            localStorage.setItem('index', index);
        },
        back: function() {
            this.$router.go(-1)
        },
        getCity: function(id) {
            this.$get("/admin/region/getCity", {
                    parent_id: id,
                })
                .then((res) => {
                    let list = res;
                    for (let i in list) {
                        list[i].val = list[i].name;
                    }
                    console.log(list)
                    // 将当前的地址 放在第一个 用于重置
                    let obj = {
                        id: this.userInfo.province_id + '-',
                        level: 1,
                        name: this.userInfo.province,
                        val: this.userInfo.province,
                        parent_id: 0
                    }
                    list.unshift(obj);
                    this.cityList = list;
                })
                .finally(() => {});
        },
        getDistrict: function(id) {
            this.$post("/admin/region/getDistrict", {
                    parent_id: id,
                })
                .then((res) => {
                    let list = res;
                    for (let i in list) {
                        list[i].val = list[i].name;
                    }
                    this.districtList = list;
                })
                .finally(() => {});
        },
        getIndustry: function() {
            this.$get("/api/Index/industry", {})
                .then((res) => {
                    if (res.code == 200) {
                        let list = res.data.list;
                        for (let i in list) {
                            list[i].val = list[i].title;
                        }
                        // console.log(list);
                        this.industryList = list || [];
                    } else {
                        return this.$message(res.msg);
                    }
                })
                .finally(() => {});
        },
        toQyDetail: function(id) {
            this.$router.push({ path: '/enterprise-detail', query: { id: id } })
        },
        setDefaultCity: function() {
            if (this.userInfo.level == 1) {
            //     if (this.$route.fullPath != '/data-platform') {
            //         this.city = this.userInfo.province;
            //     }
                this.getCity(this.userInfo.province_id)
            }

            if (this.userInfo.level > 1) {
                this.city = this.userInfo.city
                this.cityList = [{
                    id: this.userInfo.city_id,
                    level: 2,
                    val: this.userInfo.city,
                    name: this.userInfo.city,
                    parent_id: this.userInfo.province_id,
                }]
                this.getDistrict(this.userInfo.city_id)
            }
            if (this.userInfo.level > 2) {
                this.district = this.userInfo.district;
                this.districtList = [{
                    id: this.userInfo.district_id,
                    level: 3,
                    val: this.userInfo.district,
                    name: this.userInfo.district,
                    parent_id: this.userInfo.city_id,
                }]
            }
        }
    },
});