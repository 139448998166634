import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [
    {
        path: "/",
        name: "LoginPage",
        component: () =>
            import ("../views/login/index.vue"),
    }
];

const router = new VueRouter({
    mode: 'history',
    base: '/login',
    scrollBehavior(to, from, savedPosition) {
        // 解决路由跳转后 会滚动到底部
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes,
});
router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    next();
});
router.afterEach((to, from, next) => {
    // console.log("window.scrollTo", window.scrollTo);
    window.scrollTo(0, 0);
});

export default router;