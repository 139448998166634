import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './net/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import until from './until/until'
import './mixins/mixins'
Vue.prototype.$until = until;
Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false

router.afterEach((to, from, next) => {
    setTimeout(() => {
        // 此处粘贴百度统计复制过来的代码
        var _hmt = _hmt || [];
        (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?814fb6bcd3fd9567b803519af085bcb9";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }, 0);
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')