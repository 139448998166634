import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
axios.defaults.timeout = 10000; // 超时时间
let debug = false;
console.log(window.location.hostname)
axios.defaults.baseURL = (window.location.hostname == 'localhost' || window.location.hostname == 'hainan.nengkongyun.com') ? "https://hainan.nengkongyun.com/" : "https://nengkongyun.com/"; //填写域名
// axios.defaults.baseURL =  "https://hainan.nengkongyun.com/";
// axios.defaults.baseURL = "http://nengbao.tanguanjia.com/"; //填写域名
// axios.defaults.baseURL = "http://192.168.31.65:8084"; //填写域名
const type = "web";

//http request 拦截器
axios.interceptors.request.use(
    config => {
        let data = config.data || {};
        if (config.method == "get") {
            data = config.params || {};
        } else {
            data = config.data || {};
        }
        if (data.token == true) {
            data.token = localStorage["gtoken"] || "7457f3301aea6b994957b9130e15261f";
        }
        data.accountclass = 1; //企业账号2
        if (config.url == "/api/UploadFiles/upload") {
            config.headers = {
                'Content-Type': 'multipart/form-data; charset=utf-8',
                'token': localStorage["gtoken"],
                'accountclass': 1
            };
        } else {
            config.headers = {
                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            };
            config.data = qs.stringify(config.data);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    if (type == "h5") {
                        Toast("错误请求");
                    }
                    console.log("错误请求");
                    break;
                case 401:
                    if (type == "h5") {
                        Toast("未授权，请重新登录");
                    }
                    console.log("未授权，请重新登录");

                    break;
                case 403:
                    if (type == "h5") {
                        Toast("拒绝访问");
                    }
                    console.log("拒绝访问");
                    break;
                case 404:
                    if (type == "h5") {
                        Toast("未找到该资源");
                    }
                    console.log("请求错误,未找到该资源");
                    break;
                case 405:
                    if (type == "h5") {
                        Toast("请求方法未允许");
                    }
                    console.log("请求方法未允许");
                    break;
                case 408:
                    if (type == "h5") {
                        Toast("请求超时");
                    }
                    console.log("请求超时");
                    break;
                case 500:
                    if (type == "h5") {
                        Toast("服务器端出错");
                    }
                    console.log("服务器端出错");
                    break;
                case 501:
                    if (type == "h5") {
                        Toast("网络未实现");
                    }
                    console.log("网络未实现");
                    break;
                case 502:
                    if (type == "h5") {
                        Toast("网络错误");
                    }
                    console.log("网络错误");
                    break;
                case 503:
                    if (type == "h5") {
                        Toast("服务不可用");
                    }
                    console.log("服务不可用");
                    break;
                case 504:
                    if (type == "h5") {
                        Toast("网络超时");
                    }
                    console.log("网络超时");
                    break;
                case 505:
                    if (type == "h5") {
                        Toast("http版本不支持该请求");
                    }
                    console.log("http版本不支持该请求");
                    break;
                default:
                    if (type == "h5") {
                        Toast(`连接错误${err.response.status}`);
                    }
                    console.log(`连接错误${err.response.status}`);
            }
        } else {
            if (type == "h5") {
                Toast(`连接到服务器失败`);
            } else {
                // alert('连接到服务器失败')，
            }
            console.log("连接到服务器失败");
        }
        return Promise.resolve(err.response);
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
            })
            .then((response) => {
                if (response.status == 200) {
                    resolve(response.data);
                } else {

                    if (response.status == 401) {
                        this.$router.push('/');
                    }

                    this.$message(response.data.msg);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */

export function post(url, data = {}, config = {}) {
    // console.log(data)
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(
            (response) => {
                // console.log(response, "response");

                if (response.status == 200) {
                    resolve(response.data);
                } else {
                    if (response.status == 401) {
                        this.$router.push('/');
                    }

                    this.$message(response.data.msg);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

const uploadObject = axios.create({
    headers: {
        "Content-Type": "multipart/form-data; charset=utf-8",
    },
    method: "post",
});

export function upload(obj) {
    return uploadObject(obj);
}

// function request(url,data,succes,fail){
//     let xml;
//     if (window.XMLHttpRequest) {
//         xml = new XMLHttpRequest();
//     } else if (window.ActiveX) {
//         xml = new window.ActiveX('fasfasfa');
//     }
//
//     xml.setRequestHeader('Content-ty...');
//
//
//     xml.open('post','https://www.baidu.com');
//
//     xml.send();
//
//
//     xml.onreadystatechange = function (readState) {
//
//         if (xml.status === 200  && xml.readyState === 4) {
//             xml.responseText;
//         }
//
//     };
// }