// 获取今日日期
function getDates() {
    let now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;
    var day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
    var hours = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
    var minutes = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
    var seconds = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
    var today = year.toString() + month.toString() + day.toString();
    return today;
}
// 时间戳转日期
function formatDate(now_date) {
    var now = new Date(now_date)
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
}
//如果记得时间戳是毫秒级的就需要*1000 不然就错了记得转换成整型 
function isRegName(name) {
    var regName = /^[\u4e00-\u9fa5]{2,4}$/;
    if (!regName.test(name)) {
        console.error("真实姓名填写有误");
        return false;
    }
    return true;
}

function isCardNo(card) {
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X 
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(card) === false) {
        console.error("身份证输入不合法");
        return false;
    }
    return true;
}

function isPhone(phone) {
    var reg = /^[1][2,3,4,5,6,7,8][0-9]{9}$/;
    if (!reg.test(phone)) {
        console.error("手机号填写有误");
        return false;
    }
    return true;
}
// 密码由字母加数字或符号混合组成，区分大小写
function isPassword(password) {
    let reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{1,100}$/;
    let r = new RegExp(reg);
    if (r.test(password) === false) {
        return false;
    }
    return true;
}
/*
 ** 时间戳转换成指定格式日期
 ** eg.
 ** dateFormat(11111111111111, 'Y年m月d日 H时i分')
 ** → "2322年02月06日 03时45分"
 */
function dateFormat(timestamp, formats) {
    // formats格式包括
    // 1. Y-m-d
    // 2. Y-m-d H:i:s
    // 3. Y年m月d日
    // 4. Y年m月d日 H时i分
    formats = formats || 'Y-m-d';
    timestamp = timestamp * 1000;
    let zero = function(value) {
        if (value < 10) {
            return '0' + value;
        }
        return value;
    };

    let myDate = timestamp ? new Date(timestamp) : new Date();

    let year = myDate.getFullYear();
    let month = zero(myDate.getMonth() + 1);
    let day = zero(myDate.getDate());

    let hour = zero(myDate.getHours());
    let minite = zero(myDate.getMinutes());
    let second = zero(myDate.getSeconds());

    return formats.replace(/Y|m|d|H|i|s/ig, function(matches) {
        return ({
            Y: year,
            m: month,
            d: day,
            H: hour,
            i: minite,
            s: second
        })[matches];
    });
};

/**
 * 把秒数转化为天、时、分、秒
 * 参数value是秒数
 */
function formatSeconds(value) {
    var secondTime = parseInt(value) // 秒
    var minuteTime = 0 // 分
    var hourTime = 0 // 小时
    var dayTime = 0 // 天
    var result = ''
    if (value < 60) {
        result = secondTime + '秒'
    } else {
        if (secondTime >= 60) { // 如果秒数大于60，将秒数转换成整数
            // 获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60)
                // 获取秒数，秒数取佘，得到整数秒数
            secondTime = parseInt(secondTime % 60)
                // 如果分钟大于60，将分钟转换成小时
            if (minuteTime >= 60) {
                // 获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60)
                    // 获取小时后取佘的分，获取分钟除以60取佘的分
                minuteTime = parseInt(minuteTime % 60)
                if (hourTime >= 24) {
                    // 获取天数， 获取小时除以24，得到整数天
                    dayTime = parseInt(hourTime / 24)
                        // 获取小时后取余小时，获取分钟除以24取余的分；
                    hourTime = parseInt(hourTime % 24)
                }
            }
        }
        if (secondTime > 0) {
            secondTime = parseInt(secondTime) >= 10 ? secondTime : '0' + secondTime
            result = '' + secondTime + '秒'
        }
        if (minuteTime > 0) {
            minuteTime = parseInt(minuteTime) >= 10 ? minuteTime : '0' + minuteTime
            result = '' + minuteTime + '分' + result
        }
        if (hourTime > 0) {
            result = '' + parseInt(hourTime) + '小时' + result
        }
        if (dayTime > 0) {
            result = '' + parseInt(dayTime) + '天' + result
        }
    }
    return result
}
// console.log(formatSeconds('123131'));// 1天10小时12分11秒

function distanceTime(diffValue) {
    var minute = 60;
    var hour = minute * 60;
    var day = hour * 24;
    var month = day * 30;

    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    var res = '';
    var nowTime = (new Date()).getTime(); // 获取当前时间戳
    if (monthC >= 12) {
        var oldTime = nowTime - diffValue // 获取记录的时间戳
        var date = new Date(oldTime);
        res = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    } else if (monthC >= 1) {
        res = parseInt(monthC) + "个月前";
    } else if (weekC >= 1) {
        res = parseInt(weekC) + "周前"
    } else if (dayC >= 1) {
        res = parseInt(dayC) + "天前"
    } else if (hourC >= 1) {
        res = parseInt(hourC) + "小时前"
    } else if (minC >= 1) {
        res = parseInt(minC) + "分钟前"
    } else {
        res = "刚刚"
    }
    return res;
}


function numTrans(e) {
    switch (e) {
        case 0:
            return 'A'
        case 1:
            return 'B'
        case 2:
            return 'C'
        case 3:
            return 'D'
        case 4:
            return 'E'
        case 5:
            return 'F'
        case 6:
            return 'G'
        case 7:
            return 'H'
        case 8:
            return 'I'
        case 9:
            return 'G'
        case 10:
            return 'K'
        case 11:
            return 'M'
        default:
            break;
    }
}

function numTrans1(e) {
    switch (e) {
        case 'A':
            return 0
        case 'B':
            return 1
        case 'C':
            return 2
        case 'D':
            return 3
        case 'E':
            return 4
        case 'F':
            return 5
        case 'G':
            return 6
        case 'H':
            return 7
        case 'I':
            return 8
        case 'G':
            return 9
        case 'K':
            return 10
        case 'M':
            return 11
        default:
            break;
    }
}


function getWindonHref() {
    var sHref = window.location.href;
    var args = sHref.split('?');
    if (args[0] === sHref) {
        return '';
    }
    var hrefarr = args[1].split('#')[0].split('&');
    var obj = {};
    for (var i = 0; i < hrefarr.length; i++) {
        hrefarr[i] = hrefarr[i].split('=');
        obj[hrefarr[i][0]] = hrefarr[i][1];
    }
    return obj;
}


module.exports = {
    getDates,
    formatDate,
    isRegName,
    isCardNo,
    isPhone,
    isPassword,
    dateFormat,
    numTrans,
    numTrans1,
    formatSeconds, //秒数转化为时分秒
    distanceTime,
    getWindonHref
}